import React, { useEffect } from "react";

const OutlookRedirect = () => {
  useEffect(() => {
    // Function to post the authorization code to the parent window
    const postAuthCode = () => {
      // Get the authorization code from the URL
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");

      // Check if the authorization code is present
      if (!code) {
        console.error("Authorization code not found.");
        return;
      }

      // Ensure the window.opener exists and is not closed
      if (window.opener && !window.opener.closed) {
        // console.log(window.opener.location.origin)
        try {
          window.opener.postMessage(
            {
              type: "oauth_callback",
              code: code,
            },
            window.opener.location.origin
            // "https://test.gosignings.com/"
          );
        } catch (error) {
          console.error("Error posting message to opener:", error);
        }
      } else {
        console.error("No opener found or opener window is closed.");
      }

      // Close the current window
      window.close();
    };

    // Call the postAuthCode function once the component is mounted
    postAuthCode();
  }, []);

  return (
    <div>
      <h1>Processing...</h1>
    </div>
  );
};

export default OutlookRedirect;

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { json, useLocation, useNavigate } from "react-router-dom";
// import Cookie from "js-cookie";
// import { useDispatch, useSelector } from "react-redux";
// import { setauthFlowUserinfo } from "../Redux/Slices/authFlowSlice";
// import { postRequest } from "../Requests/Requests";

// const clientId = "e300ce34-2d3a-45ea-afba-ff68965a608f";
// const clientSecret = "rtF8Q~WZInhev4rzDYz8b-kvPSR3xhd94Ec6UaFD";
// const redirectUri = "http://localhost:3000/outlook/callback";

// const apiEndpoint = process.env.REACT_APP_GOSIGNING_API_ENDPOINT;

// const OutlookCallback = () => {
//   const location = useLocation();
//   const [message, setmessage] = useState("...");
//   const navigate = useNavigate();
//   // const userinfo = useSelector((state) => state.authFlow?.userinfo);
//   // const authFlowType = useSelector((state) => state.authFlow?.authFlowType);
//   const userinfo = localStorage.getItem("userinfo");
//   const authFlowType = localStorage.getItem("authFlowType");
//   const dispatch = useDispatch();

//   useEffect(() => {
//     const searchParams = new URLSearchParams(location.search);
//     const authorizationCode = searchParams.get("code");
//     const codeVerifier = localStorage.getItem("code_verifier");

//     if (authorizationCode && codeVerifier) {
//       getToken(authorizationCode, codeVerifier);
//     }
//   }, []);

//   const getToken = async (code, codeVerifier) => {
//     const tokenUrl =
//       "https://login.microsoftonline.com/common/oauth2/v2.0/token";
//     const body = new URLSearchParams({
//       client_id: clientId,
//       scope: "openid profile email Mail.Read Mail.Send",
//       code: code,
//       redirect_uri: redirectUri,
//       grant_type: "authorization_code",
//       code_verifier: codeVerifier,
//     });

//     try {
//       setmessage("Authenticating...");
//       const response = await axios.post(tokenUrl, body, {
//         headers: {
//           "Content-Type": "application/x-www-form-urlencoded",
//         },
//       });
//       const tokens = response.data;
//       if (tokens) {
//         const { access_token, refresh_token, expires_in } = tokens;

//         getUserInfo(access_token, refresh_token);
//       }
//     } catch (error) {
//       return null;
//     }
//   };

//   const getUserInfo = async (accessToken, refresh_token) => {
//     try {
//       setmessage("Getting your info...");
//       const response = await axios.get("https://graph.microsoft.com/v1.0/me", {
//         headers: {
//           Authorization: `Bearer ${accessToken}`,
//         },
//       });

//       const userinfo = response.data;

//       // dispatch(setauthFlowUserinfo(userinfo));
//       localStorage.setItem("userinfo", JSON.stringify(userinfo));
//       localStorage.setItem("refresh_token", refresh_token);
//       if (authFlowType == "signin") {
//         navigate("/Signin");
//       } else if (authFlowType == "register") {
//         navigate("/SignUp");
//       } else {
//         console.log("Else");
//       }
//     } catch (error) {
//       return null;
//     }
//   };
//   return (
//     <div
//       style={{
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         height: window.innerHeight,
//         width: window.innerWidth,
//       }}
//     >
//       <h1>{message}</h1>
//     </div>
//   );
// };

// export default OutlookCallback;
