import { Grid, List, ListItem, Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import React from 'react';
import SliderComponent from '../Components/ManageJobSlider.jsx';
import LandingSliderData from "../Components/LandingSliderData";
import CarouselComponent from "../Components/CarouselSlider";
import bubble5 from '../Assets/Images/bubble5.png'
import bubble4 from '../Assets/Images/bubble4.png'

const slidesData = [
    {
        heading: "SaaS Solutions",
        text: "Explore our software as a service offerings to boost your business efficiency."
    },
    {
        heading: "ERP Integration",
        text: "Streamline your operations with our custom ERP solutions."
    },
    {
        heading: "CRM Systems",
        text: "Manage customer relationships effectively with our CRM tools."
    },
    {
        heading: "E-Commerce Platforms",
        text: "Build and scale your online store with our e-commerce solutions."
    }
];

const ManageJob = () => {
    return (
        <Box sx={{ backgroundColor: '#ffffff', width: '100%', padding: '50px 0' }}>
           <img
          src={bubble4}
          alt="Bubble 4"
          style={{
            position: 'absolute',
            objectFit: 'contain',
            // zIndex: 1,
            height: '130%',
            opacity: '0.9',            
            left: '0px',
            marginTop: '-60px'
          }}
          className="bubble-img"
        />
        <img
          src={bubble5}
          alt="Bubble 2"
          style={{
            position: 'absolute',
            objectFit: 'contain',
            // zIndex: 1,
            height: '130%',
            opacity: '0.9',            
            right: '0px',
            marginTop: '-90px'
          }}
          className="bubble-img"
        />
            <Container>
                
                <Grid container spacing={2} sx={{display: 'flex'}}>
                    <Grid item xs={12} md={12}>
                        <Typography
                            variant="h4"
                            sx={{
                                fontSize: { xs: '36px', md: '40px' }, // Responsive font size
                                color: '#000000',
                                fontWeight: 600,
                                textAlign: {xs: 'left', md: 'center'},                            
                            }}
                        >
                            Don't Just Manage Jobs, Close Them <br />Faster: Why <span style={{ color: '#F26F21' }}>GoSignings</span> is Different
                        </Typography>
                    </Grid>
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Grid item xs={12} md={11}>
                        <Typography sx={{
                            fontSize: '21px',
                            color: '#000000',
                            textAlign: {xs: 'left', md: 'center'},
                            padding: {md: '55px 0px', xs: '1rem 0px 2em 1rem'},

                            fontWeight: {xs: 400, md: 600}
                        }}>
                            GoSignings automates job acceptance, scheduling, and alerts, ensuring efficient management of notary jobs. By Integrating with your calendar to prevent conflicts, it notifies you promptly of new assignments via SMS, so you can focus on delivering exceptional service and closing deals swiftly.
                        </Typography>
                    </Grid>
                    </Box>
                    <Grid xs={12} md={12} sm={12}>
                    <CarouselComponent LandingSliderData={LandingSliderData} />
                    </Grid>
                    

                </Grid>
               
            </Container>
        </Box>
    );
};

export default ManageJob;


