import React from 'react';
import { Box, Grid, Typography, List, ListItem, ListItemText } from '@mui/material';
import { Container } from '@mui/system';
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Solutionimg from '../Assets/Images/solution.png'
import Solutionhead from '../Assets/Images/Solutionhead.png'

const Solution = ({ image, Heading, Heading1, Text1, Heading2, Text2, Heading3, Text3 }) => {
  const points = [
    'Auto-evaluates and accepts new orders',
    'Monitors the notary’s inboxes for new invitations',
    'Adds accepted new orders to the notary’s calendar',
  ];
  return (
    <Box
      sx={{
        background: "#ffe1cf",
        padding: '20px', // Add some padding for better spacing
      }}
    >
      <Container sx={{marginTop: {xs: '40px', md: '90px'}, marginBottom: '20px', paddingLeft: {xs: '0px', sm: 'auto', md: 'auto'}}}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            lg={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Box sx={{ display: { lg: "block", xs: "block" } }}>
              <img
                src={Solutionimg}
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  marginLeft: {xs: '5px', md: '-10px'}
                  // objectFit: "cover",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Timeline sx={{ m: { xs: 0 }, p: { xs: 0 } }}>
              {/* First Timeline Item */}
              <TimelineItem className="space" sx={{paddingLeft: {md: '10px', sm: '25px'}}}>
                <TimelineSeparator sx={{paddingLeft: {md: '0%', xs: '3%'}}}>
                  <TimelineDot
                    sx={{
                      bgcolor: "#6a9b41",
                      width: { xs: "45px", sm: "70px", md: "80px" }, // Adjusted for different screen sizes
                      height: { xs: "45px", sm: "70px", md: "80px" },
                      boxShadow: 0,
                      zIndex: 9,
                      m: 0,
                      paddingLeft: {md: '1%', xs: '4%'},
                      marginLeft: { xs: '5px', md: '-10px' }, // Adjusted margin for small screens
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <img
                        src={Solutionhead}
                        alt="Challenges"
                        style={{
                          width: "60%",
                          height: "auto",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                  </TimelineDot>
                  <TimelineConnector
                    className="customConnector"
                    sx={{
                      backgroundColor: "#54812f",
                      height: "50px",
                      width: "1px",
                       marginLeft: '8px'
                    }}
                  />
                </TimelineSeparator>
                <TimelineContent>
                  <Typography
                    sx={{
                      color: '#ffffff',
                      display: "flex",
                      fontSize: { lg: "28px", sm: "20px", xs: "16px" }, // Adjusted font size
                      fontWeight: "700",
                      backgroundColor: "#54812f",
                      ml: { lg: "-13%", xs: "-20%", sm: '-25px' }, // Adjust margin for small screens
                      borderRadius: "5px",
                      mt: { lg: 1, xs: 0.8 },
                      pl: { lg: 8, xs: 4, },
                      py: { lg: 1, xs: 0.8 }, // Add padding for better spacing
                      lineHeight: {xs: 'auto', md: '55px'}
                    }}
                  >
                    Solutions
                  </Typography>
                </TimelineContent>
              </TimelineItem>

              {/* Second Timeline Item */}
              <TimelineItem className="space">
                <TimelineSeparator sx={{ pl: { lg: "4%", xs: "6%" } }}>
                  <TimelineDot
                    sx={{
                      bgcolor: "#6a9b41",
                      width: { xs: "35px", sm: "40px", md: "45px" }, // Smaller size for better responsiveness
                      height: { xs: "35px", sm: "40px", md: "45px" },
                      boxShadow: 0,
                      m: 0,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                        fontSize: '30px'
                      }}
                    >
                      1
                    </Box>
                  </TimelineDot>
                  <TimelineConnector
                    sx={{
                      backgroundColor: "#54812f",
                      height: { xs: "70px", md: "100px" }, // Adjusted connector height
                      width: "1px",
                    }}
                  />
                </TimelineSeparator>
                <TimelineContent>
                  <Typography
                    sx={{
                      fontSize: { lg: "16px", xs: "12px" }, // Font adjustment
                      fontWeight: "500",
                      color: "#000000",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 'bolder',
                        fontSize: '18px', // Changed font size
                      }}
                    >
                      Automation of Job Management:
                    </span>{" "}
                     By implementing GoSignings to automate job acceptance, scheduling, and notification processes, thus reducing manual effort and improving With efficiency So You Can Focus On The Task At Hand.
                  </Typography>
                </TimelineContent>
              </TimelineItem>

              {/* Third Timeline Item */}
              <TimelineItem className="space">
                <TimelineSeparator sx={{ pl: { lg: "4%", xs: "6%" } }}>
                  <TimelineDot
                    sx={{
                      bgcolor: "#6a9b41",
                      width: { xs: "35px", sm: "40px", md: "45px" }, // Keep consistent size with the previous dot
                      height: { xs: "35px", sm: "40px", md: "45px" },
                      boxShadow: 0,
                      m: 0,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                        fontSize: '30px'
                      }}
                    >
                      2
                    </Box>
                  </TimelineDot>
                  <TimelineConnector
                    sx={{
                      backgroundColor: "#54812f",
                      height: { xs: "70px", md: "100px" }, // Adjusted connector height for small screens
                      width: "1px",
                    }}
                  />
                </TimelineSeparator>
                <TimelineContent>
                  <Typography
                    sx={{
                      fontSize: { lg: "16px", xs: "12px" }, // Font adjustment for better readability
                      fontWeight: "500",
                      color: "#000000",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 'bolder',
                        fontSize: '18px',
                      }}
                    >
                      Integrated Calendar:
                    </span>{" "}
                     Using integrated calendar systems that prevent scheduling conflicts by syncing with personal calendars and providing real-time updates on job assignments.
                  </Typography>
                </TimelineContent>
              </TimelineItem>

              {/* Fourth Timeline Item */}
              <TimelineItem className="space">
                <TimelineSeparator sx={{ pl: { lg: "4%", xs: "6%" } }}>
                  <TimelineDot
                    sx={{
                      bgcolor: "#6a9b41",
                      width: { xs: "35px", sm: "40px", md: "45px" },
                      height: { xs: "35px", sm: "40px", md: "45px" },
                      boxShadow: 0,
                      m: 0,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                        fontSize: '30px'
                      }}
                    >
                      3
                    </Box>
                  </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                  <Typography
                    sx={{
                      fontSize: { lg: "16px", xs: "12px" },
                      fontWeight: "500",
                      color: "#000000",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 'bolder',
                        fontSize: '18px',
                      }}
                    >
                      Instant Communication:
                    </span>{" "}
                     Adopting instant communication tools such as SMS alerts or dedicated messaging platforms to ensure quick and effective communication with clients And Job Schedulers, Thus Enhancing Overall Service Delivery.
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            </Timeline>

          </Grid>
        </Grid>
        <Typography
          sx={{
            fontSize: { lg: "40px", xs: "28px" },
            fontWeight: 700,
            color: "#000000",
            marginTop: '40px',            
          }}
        >
          <span
            style={{
              fontWeight: 700,
              fontSize: { lg: "40px", xs: "28px" }, // Change the font size here
              color: '#F26F21'
            }}
          >
            “GoSignings”
          </span>  Will Solve This Problem!
        </Typography>
            <List>
              {points.map((point, index) => (
                <ListItem
                  key={index}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 10', // Adjust padding for list items
                    
                  }}
                >
                  <Box
                    component="span"
                    sx={{
                      width: {md: '8px', xs: '11px'},
                      height: '8px',
                      borderRadius: '50%',
                      backgroundColor: '#000000', // Filled circle color
                      marginRight: '12px', // Space between bullet and text
                    }}
                  />
                  <ListItemText
                    primary={point}
                    sx={{ margin: 0, fontSize: '20px', color: '#000000' }} // Adjust font size for list items
                  />
                </ListItem>
              ))}
            </List>
      </Container>
    </Box>
  );
};

export default Solution;
