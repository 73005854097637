
import React, { useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import SelectLanguage from "../Components/LanguageSelect";
import Logo from "../Components/Logo";
import Button from "../Components/Button";
import HeadingText from "../Components/Headingtext.jsx"; // Corrected the file name to match the standard PascalCase
import ImageText from "../Components/ImageText.jsx";
import Solution from "../Components/Solution.jsx";
import Signup from "../Components/Signup.jsx";
import TermsAndConditions from "../Pages/Auth/TermsAndConditions";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import CoreFeatures from "../Components/CoreFeatures.jsx";
import TraditionalMethod from "../Components/TraditionalMethod.jsx";
import TextImageviseversa from "../Components/TextImageviseversa.jsx";

import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";

import bubble1 from '../Assets/Images/bubble1.png'
import bubble7 from '../Assets/Images/bubble7.png'
import bubble5 from '../Assets/Images/bubble5.png'
import bubble2 from '../Assets/Images/bubble2.png'
import bubble3 from '../Assets/Images/bubble3.png'
import Challbackground from '../Assets/Images/Challbackground.png';
import Challenges2 from "../Assets/Images/Challenges2.png";
import Solutionimg from "../Assets/Images/solution.png";
import Solutionhead from "../Assets/Images/Solutionhead.png";
import circles from "../Assets/Images/anda.png";
import banner from "../Assets/Images/banner.png";
import Numbering from "../Assets/Images/Number1.png";
import Manual from "../Assets/Images/Manual.png";
import onestep from "../Assets/Images/onestep.png";
import Chanllenges from "../Assets/Images/Challenges1.png";
import Solution1 from "../Assets/Images/solution.png";
import InstagramIcon from "../Assets/Images/ginsta.png";
import FacebookIcon from "../Assets/Images/gfb.png";
import LinkedinIcon from "../Assets/Images/glinked.png";
import Twitter from "../Assets/Images/gx.png";
import ManageJob from "../Components/ManageJob.jsx";
import NumberingSlider from "../Components/NumberingSlider.jsx";
import AuthTopBgImg from "../Assets/Images/AuthTopBgPic.png";
import stopbg from '../Assets/Images/stopbg.png'

import Icon1 from "../Assets/Images/tracking.png";
import Icon2 from "../Assets/Images/icon2.png";
import Icon3 from "../Assets/Images/icon3.png";
import Icon4 from "../Assets/Images/icon4.png";
import Icon5 from "../Assets/Images/icon5.png";
import Icon6 from "../Assets/Images/icon6.png";
import Icon7 from "../Assets/Images/icon7.png";
import Icon8 from "../Assets/Images/icon8.png";

import Icon12 from "../Assets/Images/icon12.png";

import Announcement from "../Assets/Icons/Announcement.png";

// import job1 from '../Assets/Images/job1.png';
// import job2 from '../Assets/Images/job2.png';
// import job3 from '../Assets/Images/job13.png';
// import job4 from '../Assets/Images/job4.png';
// import job5 from '../Assets/Images/job5.png';
// import job6 from '../Assets/Images/job6.png';
// import job7 from '../Assets/Images/job7.png';
import Card from "../Components/Card.jsx";
import Jobmanagment from "../Components/Jobmanagment.jsx";
import { border, borderColor, textAlign } from "@mui/system";

const LandingPage_1 = () => {
  // const slideData = [
  //     { number: 1, heading: "Sign Up" },
  //     { number: 2, heading: "Set your job acceptance criteria" },
  //     { number: 3, heading: "Link your Signing Agency to Our Application" },
  //     { number: 4, heading: "Get Your Ideal Jobs" },
  //     { number: 5, heading: "GoSignings will Add The Job closing to your calendar" },
  //     { number: 6, heading: "Close the Job and Get Paid" },
  //   ];

  const slideData = [
    { number: 1, heading: "Sign Up                                              " },
    { number: 2, heading: "Set your job acceptance criteria                     " },
    { number: 3, heading: "Link your Signing Agency to Our Application          " },
    { number: 4, heading: "Get Your Ideal Jobs                                  " },
    { number: 5, heading: "GoSignings will Add The Job closing to your calendar " },
    { number: 6, heading: "Close the Job and Get Paid                           " },
  ];

  const [agreeTerms, setAgreeTerms] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const TermsCondition = () => {
    setShowModal(true);
  };

  return (
    <Box
      sx={{
        height: { md: "auto", xs: "auto" },
        background: "linear-gradient(90deg, #ffffff 40%, #fce7d3 60%)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#d6ebcc",
        }}
      >
        <img
          src={Announcement}
          alt="Announcement"
          style={{ marginRight: "10px", height: "30px", width: "30px" }} // Adjust size as needed
        />
        <Typography
          sx={{
            fontSize: { md: "14px", xs: "8px" },
            color: "black",
            lineHeight: { md: "40px", xs: "16px" },
            textAlign: "center",
          }}
        >
          Announcement: We sincerely apologize for the inconvenience. Our system
          is currently down, and we are working diligently to resolve the issue.
          Your patience is greatly appreciated.
        </Typography>
      </Box>
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: { md: "center", xs: "flex-start", sm: "flex-start" },
            marginTop: "20px",
          }}
        >
          <Logo sx={{ width: { sx: "40%" } }} />
          <Box
            sx={{ display: { lg: "flex", xs: "none" }, alignItems: "center" }}
          >
            <Link
              to=""
              onClick={() => {
                const section = document.getElementById('Howitworks');
                if (section) {
                  section.scrollIntoView({ behavior: 'smooth' });
                }
              }}
              style={{ textDecoration: "none", color: "#000000" }}
            >
              <Typography sx={{ fontSize: "18px", fontWeight: 400, mx: 2 }}>
                How It Works
              </Typography>
            </Link>
            <Link
              to=""
              onClick={() => {
                const section = document.getElementById('core-feature');
                if (section) {
                  section.scrollIntoView({ behavior: 'smooth' });
                }
              }}
              style={{ textDecoration: "none", color: "#000000" }}
            >
              <Typography sx={{ fontSize: "18px", fontWeight: 400, mx: 2 }}>
                Features
              </Typography>
            </Link>

             <Link to=""
              onClick={() => {
                const section = document.getElementById('Pricing');
                if (section) {
                  section.scrollIntoView({ behavior: 'smooth' });
                }
              }}
              style={{ textDecoration: "none", color: "#000000" }}
            >
              <Typography sx={{ fontSize: "18px", fontWeight: 400, mx: 2 }}>
                Pricing
              </Typography>
            </Link>
            <Link
              to="/contactus"
              style={{ textDecoration: "none", color: "#000000" }}
            >
              <Typography sx={{ fontSize: "18px", fontWeight: 400, mx: 2 }}>
                Contact Us
              </Typography>
            </Link>
            <SelectLanguage
              dropdownDirection="down"
              sx={{ display: { lg: "block", xs: "none" } }}
            />
          </Box>
          <Link to="/Signin" style={{ textDecoration: "none" }}>
            <Button
              btnname="Sign In"
              variant="outlined"
              sx={{
                fontSize: { lg: "19px", xs: "12px", sm: "16px" },
                textTransform: "none",
                width: { lg: "200px", xs: "80px", sm: "200px" },
                height: { lg: "60px", xs: "32px", sm: "50px" },
                border: { xs: "1px solid #7cb74a", md: "2px solid #7cb74a", sm: "2px solid #7cb74a" },
                borderRadius: "7px",
                color: "#67973F",
                borderColor: "#67973F",
                "&:hover": {
                  backgroundColor: "#7cb74a",
                  color: "white",
                  border: "1px solid #7cb74a",
                },
              }}
            />
          </Link>
        </Box>
        <Box
          sx={{
            backgroundImage: `url(${circles})`,
            backgroundSize: { md: "40%", xs: "105%" }, // Adjusts image size based on screen size
            backgroundRepeat: "no-repeat",
            display: "flex",
            backgroundPosition: { md: "right", xs: "bottom" }, // Stays on the right for larger screens, bottom for mobile
            opacity: "0.9",
            marginTop: { md: "80px", xs: "40px" }, // Aligns the layout based on screen size
            position: "relative", // Ensures it stays within its container
          }}
        >
          <Grid container spacing={4}>
            <Grid item md={8} xs={12}>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  lineHeight: { md: "130%" },
                  fontSize: { lg: "40px", xs: "32px" },
                  fontWeight: 600,
                  textTransform: "capitalize",
                  color: "#000000",
                }}
              >
                Revolutionizing How Notary Jobs Are Closed With AI-Powered
                <span style={{ color: "#F26F21" }}> GoSignings!</span>
              </Typography>
              <Typography
                sx={{
                  marginTop: "20px",
                  fontSize: { lg: "32px", xs: "24px" },
                  fontWeight: 600,
                  lineHeight: "133.3%",
                  textTransform: "capitalize",
                  color: "000000",
                }}
              >
                “GoSignings helps mobile notaries get more business and save
                time”
              </Typography>

              <Typography
                sx={{
                  marginTop: "20px",
                  fontSize: { lg: "24px", xs: "20px" },
                  fontWeight: 100,
                  color: "000000",
                  lineHeight: "150%",
                  fontFamily: "Inter",
                }}
              >
                “GoSignings automates the process of evaluating and accepting
                new job offers from signing agencies based on your acceptance
                criteria. While you go about your busy day, GoSignings is
                listening for new invitations from your signing agencies,
                evaluating and accepting new closings for you, notifying you of
                accepted work, and adding the closings to your calendar.”
              </Typography>
              <Link to={"/Signup"} style={{ textDecoration: "none" }}>
                <Button
                  btnname="Register"
                  className="Orangebtn"
                  variant="contained"
                  sx={{
                    fontFamily: "Inter",
                    color: "white",
                    fontSize: "24px",
                    fontWeight: "600",
                    width: { md: "50%", xs: "100%" },
                    height: "60px",
                    marginTop: { xs: "50px", md: "10px" },
                    borderRadius: "12px",
                    textTransform: "none",
                  }}
                />
              </Link>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: { md: "flex-end", xs: "center" },
                paddingTop: { xs: '0px', md: 'auto' }
              }}
            >
              <Box
                component="img"
                src={banner}
                alt="Banner"
                sx={{
                  width: { md: "auto", xs: "90%", sm: "70%" },
                  height: { md: "650px", xs: "auto", sm: "680px" },
                  paddingTop: '36px'
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Box
        sx={{
          backgroundColor: "#ffe1cf",
          height: { md: "280.01px", xs: '600px' },
          display: "flex",
          alignItems: "center",
        }}
      >
        <Container>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: { md: "38px", xs: "34px" },
              fontWeight: "bolder",
              lineHeight: "120%",
              color: "#000000",
              textAlign: { md: "center", xs: "left" },
            }}
          >
            “Made For Mobile Notaries By Mobile Notaries Teamed With High
            Caliber Software Developers To Create An Application To Totally
            Change Their Lives.”
          </Typography>
        </Container>
      </Box>
      <Box
        sx={{
          backgroundColor: "#ffffff",
          height: { xs: "auto", md: "500.01px" },
          display: "flex",
          alignItems: "center",
        }}
      >
        <Container sx={{ marginTop: { md: '25px', xs: '10px' } }}>
          <HeadingText
            heading="Revolutionizing Notary Jobs Closing"
            text="GoSignings automates your mobile notary jobs by accepting or rejecting based on your preferences and adding them to your calendar. This streamlined process ensures your jobs are closed automatically, allowing you to focus on your work."
          />
          <NumberingSlider slideData={slideData} />
        </Container>
      </Box>
      <ImageText
        hei="550px"
        Image={Manual}
        Heading="Free Yourself from Manual Jobs Closing ~ Embrace Automation with "
        Text="GoSignings, your go-to mobile notary and job closing AI management system. Automate time-consuming tasks and focus on delivering top-notch service and closing more jobs. Revolutionize your workflow with GoSignings!"
        coloredWord="“GoSignings!”"
      />
      {/* <Box sx={{ backgroundColor: '#ffffff', height: {xs: 'auto', md: '280.01px'}, display: 'flex', alignItems: 'center' }}>
                <Container>

                    <HeadingText
                        heading="Traditional Method Of A Mobile Notary Job Closing"
                        text="Traditional mobile notary systems often lead to missed opportunities and lost income due to manual processes and inefficient scheduling systems."
                    />
                </Container>
            </Box> */}
      <Box
        sx={{
          backgroundColor: "#fef9f4",
          backgroundImage: `url(${Challbackground})`, // Using the imported image
          backgroundSize: 'cover', // Ensures the background image covers the entire Box
          backgroundPosition: 'center', // Positions the background image at the center
          backgroundRepeat: 'no-repeat', // Prevents the image from repeating
          display: "flex",
          alignItems: "center",
          padding: { xs: "0px", md: "80px" },
        }}
      >
        <Container>
          <Timeline sx={{ m: 0, p: 0 }}>
            <TimelineItem className="space">
              <TimelineSeparator>
                <TimelineDot
                  sx={{
                    bgcolor: "#6a9b41",
                    width: { xs: "50px", sm: "70px", md: "80px" }, // Adjusted size for different screen sizes
                    height: { xs: "50px", sm: "70px", md: "80px" },
                    boxShadow: 0,
                    zIndex: 9,
                    m: 0,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src={Chanllenges}
                      alt="Challenges"
                      style={{
                        width: "80%", // Keep the image responsive inside the dot
                        height: "auto",
                        objectFit: "cover",
                      }}
                    />
                  </Box>
                </TimelineDot>
              </TimelineSeparator>

              <TimelineContent>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "#FFFFFF",
                    fontSize: { lg: "24px", md: "20px", xs: "16px" }, // Adjusted font size
                    fontWeight: "600",
                    backgroundColor: "#54812f",
                    width: { xs: "100%", md: "40%" },
                    height: "auto", // Let the content determine the height
                    ml: { lg: "-6%", xs: "-20%", sm: "-38px" }, // Adjust for mobile and large screens
                    borderRadius: "5px",
                    mt: { lg: 1, xs: 1 },
                    pl: { lg: 8, xs: 4 },
                    py: 1, // Add padding for better vertical alignment
                    lineHeight: { xs: 'auto', md: '55px' }
                  }}
                >
                  Challenges
                </Typography>
              </TimelineContent>
            </TimelineItem>
          </Timeline>

          <Typography
            sx={{
              color: "black",
              fontSize: { xs: "18px", md: "32px" },
              fontWeight: { xs: "800", md: "700" },
              textAlign: { xs: "left", md: "left" },
              paddingLeft: { xs: '70px', md: '110px', sm: '75px' },
              marginTop: { xs: "20px", md: "0px" },
            }}
          >
            Mobile Notaries are Missing Work Opportunities
          </Typography>

          <Grid
            container
            spacing={2}
            sx={{ alignItems: { md: "flex-start", xs: "center" }, marginTop: { xs: "0px", md: "50px" } }}
          >
            {/* Left Side */}
            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                paddingLeft: "0px",
              }}
            >
              {[1, 2, 3].map((num, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: { xs: "flex-start", md: "center" },
                    margin: { xs: "5px 0px", md: "15px 0px" },
                  }}
                >
                  <Timeline sx={{ m: 0, p: 0 }}>
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot
                          sx={{
                            bgcolor: "#6a9b41",
                            width: { xs: "30px", md: "53px" }, // Reduced size
                            height: { xs: "30px", md: "53px" }, // Reduced size
                            boxShadow: 0,
                            zIndex: 9,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "white",
                            fontSize: { xs: "20px", md: "30px" }, // Reduced font size for numbers
                            fontWeight: "bold",
                          }}
                        >
                          {num}
                        </TimelineDot>
                      </TimelineSeparator>
                    </TimelineItem>
                  </Timeline>

                  <Typography
                    sx={{ fontSize: { xs: "16px", md: "18px" }, color: "#000000", mx: 2, fontWeight: { xs: 500, md: 600, sm: 400 }, paddingTop: { xs: '10px', md: 'auto' } }}
                  >
                    {/* Your text here */}
                    {index === 0
                      ? "New opportunities arrive constantly"
                      : index === 1
                        ? "Can I accept these invitations?"
                        : "I have to respond within 15 seconds"}
                  </Typography>

                  <EastIcon
                    sx={{
                      color: "#6a9b41",
                      fontSize: "30px",
                      transform: "scaleX(1.8)",
                      display: { xs: "none", lg: "block" },
                    }}
                  />
                </Box>
              ))}
            </Grid>

            {/* Middle Image */}
            <Grid
              item
              xs={12}
              sm={4}
              sx={{ display: "flex", justifyContent: "center", paddingTop: { md: 'auto', xs: '0px !important' } }}
            >
              <img
                src={Challenges2}
                alt="Challenges"
                style={{ maxWidth: "70%", height: "auto" }}
              />
            </Grid>

            {/* Right Side */}
            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                paddingLeft: "0px",
              }}
            >
              {[4, 5].map((num, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: { xs: "flex-start", md: "center" },
                    margin: { xs: "10px 0px", md: "15px 0px" },
                    paddingBottom: { xs: '10px', md: 'auto' }
                  }}
                >
                  <WestIcon
                    sx={{
                      color: "#6a9b41",
                      fontSize: "30px",
                      transform: "scaleX(1.8)",
                      display: { xs: "none", lg: "block" },
                    }}
                  />
                  <Timeline sx={{ m: 0, p: 0 }}>
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot
                          sx={{
                            bgcolor: "#6a9b41",
                            width: { xs: "30px", md: "53px" }, // Reduced size
                            height: { xs: "30px", md: "53px" }, // Reduced size
                            boxShadow: 0,
                            zIndex: 9,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "white",
                            fontSize: { xs: "24px", md: "30px" }, // Reduced font size for numbers
                            fontWeight: "bold",
                          }}
                        >
                          {num}
                        </TimelineDot>
                      </TimelineSeparator>
                    </TimelineItem>
                  </Timeline>

                  <Typography
                    sx={{ fontSize: { xs: "16px", md: "18px" }, color: "#000000", mx: 2, fontWeight: { xs: 500, md: 600, sm: 400 }, paddingTop: { xs: '10px', md: 'auto' } }}
                  >
                    {/* Your text here */}
                    {index === 0
                      ? "I’m always on the road.  I don’t have time to evaluate these. I’m losing out on many business  opportunities!"
                      : "Multiple invitations arrive at the same time. I can’t respond to all of them in time!"}
                  </Typography>
                </Box>
              ))}
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Solution
      // image={Solution1}
      // Heading='Solutions'
      // Heading1='Automation of Job Management:'
      // Heading2='Integrated Calendar:'
      // Heading3='Instant Communication:'
      // Text3='Adopting instant communication tools such as SMS alerts or dedicated messaging platforms to ensure quick and effective communication with clients And Job Schedulers, Thus Enhancing Overall Service Delivery.'
      // Text2='Using integrated calendar systems that prevent scheduling conflicts by syncing with personal calendars and providing real-time updates on job assignments.'
      // Text1='By implementing GoSignings to automate job acceptance, scheduling, and notification processes, thus reducing manual effort and improving With efficiency So You Can Focus On The Task At Hand.'
      />
      <Box
        sx={{
          padding: { xs: '0', sm: '50px 10px', md: '100px 0px' }, // Responsive padding
          backgroundColor: '#fff', // Dynamic backgroundColor
        }}
      >
        <Container
          sx={{
            width: '100%', // Full width for responsiveness
            height: 'auto', // Customizable height
            background: 'linear-gradient(135deg, #6a9b41, #b8b16f)', // Gradient background
            borderRadius: { xs: '16px', md: '8px' }, // Rounded corners
            padding: { xs: '10px', md: '40px' }, // Responsive padding

            '@media (max-width: 600px)': {
              padding: '25px', // Adjust padding for smaller screens
            },
          }}
        >
          <Grid container spacing={2} sx={{ alignItems: 'center' }}>
            {/* First Column */}
            <Grid item xs={12} md={6}>

              <Typography sx={{
                fontSize: { xs: "36px", sm: "36px", md: "36px" }, // Responsive font size
                fontWeight: "bolder",
                marginBottom: "5px",
                paddingLeft: { md: '40px', xs: 'auto' },
                textAlign: { md: 'left', xs: 'left', sm: 'center' },
                color: 'white'
              }}>
                Start Your Free 30-Day Trial
              </Typography>
              <Typography sx={{
                fontSize: { xs: "28px", sm: "28px" }, // Responsive font size
                fontWeight: "500",
                lineHeight: "",
                marginBottom: "5px",
                paddingLeft: { md: '0px', xs: 'auto' },
                marginBottom: { xs: '24px', md: '24px' }, // Adjust margins
                // padding: { xs: '10px 40px', md: 'auto' },
                paddingLeft: { xs: 'auto', md: '40px' },
                textAlign: 'left !important',
                color: 'white'
              }}>
                (no credit card required)
              </Typography>

            </Grid>

            {/* Second Column */}
            <Grid item xs={12} md={6}>
             
                <Button
                  btnname="Explore GoSignings Today!"
                  variant="contained"
                  className="Orangebtn"
                  sx={{

                    width: '75%',
                    height: '60px',
                    fontSize: '24px',
                    fontWeight: '400',
                    textTransform: 'none',
                    borderRadius: '10px',
                    padding: { xs: '8px 16px', md: '10px 20px' }, // Adjust button size
                    fontSize: { xs: '12px', md: '24px' }, // Responsive font size for button
                  }}
                />
             
            </Grid>
          </Grid>

        </Container>
      </Box>

      <Signup />

      <Box sx={{ backgroundColor: "#ffffff" }}>
        <img
          src={bubble1}
          alt="Bubble 1"
          style={{
            position: 'absolute',
            // top: 0,
            // right: 0,
            objectFit: 'contain',
            // zIndex: 1,
            // rotate: '270deg',
            height: 'auto',
            opacity: '0.5',
            width: '250px',
            paddingTop: '50px'
            // width: '223px',
            // top: '0px',
            // right: '0px',
          }}
          className="bubble-img"
        />
        <img
          src={bubble2}
          alt="Bubble 2"
          style={{
            position: 'absolute',
            objectFit: 'contain',
            zIndex: 1,
            height: 'auto',
            opacity: '0.9',
            top: '6250px',
            right: '0px',
            width: '160px'
          }}
          className="bubble-img"
        />
        <img
          src={bubble3}
          alt="Bubble 3"
          style={{
            position: 'absolute',
            objectFit: 'contain',
            height: 'auto',
            top: '7200px',
            width: '200px',
            right: '0px',
          }}
          className="bubble-img"
        />
        <img
          src={bubble7}
          alt="Bubble 2"
          style={{
            position: 'absolute',
            objectFit: 'contain',
            width: 'auto',
            height: '310px',
            top: '7870px',
            left: '0px',
            // rotate: '180deg'
          }}
          className="bubble-img"
        />
        <img
          src={bubble5}
          alt="Bubble 2"
          style={{
            position: 'absolute',
            objectFit: 'contain',
            height: 'auto',
            top: '8250px',
            right: '0px',
            width: '250px'

          }}
          className="bubble-img"
        />
        <Container>
          <Grid container spacing={2} sx={{ justifyContent: "center" }}>
            <Grid
              item
              xs={12}
              md={12}
              sx={{ marginBottom: { xs: "0px", md: "60px" } }}
            >
              <Typography
                sx={{
                  fontSize: "40px",
                  color: "#000000",
                  fontWeight: "600",
                  textAlign: "center",
                  margin: "10px 0px",
                }}
              >
                Traditional Method Of A Mobile Notary Job Closing
              </Typography>
              <Typography
                sx={{
                  fontSize: "24px",
                  color: "#000000",
                  fontWeight: "100",
                  textAlign: "center",
                }}
              >
                Traditional mobile notary systems often lead to missed
                opportunities and lost <br /> income due to manual processes and
                inefficient scheduling systems.
              </Typography>


            </Grid>
            {/* Timeline Section */}
            <Grid
              item
              xs={12}
              md={4}
              sm={6}
              sx={{ marginTop: { xs: "0px", md: "30px" }, paddingLeft: "0px" }}
            >
              <TraditionalMethod
                number="1"
                Icon={Icon1}
                Heading="Manual Tracking"
                Text="Receives job invitations through emails, phone calls, or text messages, which must be manually tracked and managed."
              />
            </Grid>
            <Grid item xs={12} md={4} sm={6} sx={{ marginTop: { xs: '0px', md: "-20px" } }}>
              <TraditionalMethod
                number="2"
                Icon={Icon2}
                Heading="Manual data gathering"
                Text="Notaries Must review the terms of new invitations. This is difficult and even unsafe if the notary is driving."
              />
            </Grid>
            {/* Widened Section */}
            <Grid item xs={12} md={4} sm={6} sx={{ marginTop: { xs: '0px', md: "25px" } }}>
              <TraditionalMethod
                number="3"
                Icon={Icon3}
                Heading="Job Assessment"
                Text="Notaries must check their calendar for schedule conflicts, guess if they have enough time to drive to a new closing, and consider if the fee being offered is sufficient, all while driving or working."
              />
            </Grid>
            <Grid item xs={12} md={4} sm={6} sx={{ marginTop: { xs: '0px', md: "30px" } }}>
              <TraditionalMethod
                number="4"
                Icon={Icon4}
                Heading="Manual RSVP:"
                Text="Notaries must manually respond to signing agencies, who expect an immediate response. Missed invitations are quickly awarded to other notaries."
              />
            </Grid>
            <Grid item xs={12} md={4} sm={6} sx={{ marginTop: { xs: '0px', md: "-20px" } }}>
              <TraditionalMethod
                number="5"
                Icon={Icon5}
                Heading="Confirmation receipt notification"
                Text="If the notary accepted the job, were they awarded the work? Now, the notary has to check their inbox for confirmation constantly. This is distracting and time-consuming."
              />
            </Grid>
            {/* Widened Section */}
            <Grid item xs={12} md={4} sm={6} sx={{ marginTop: { xs: '0px', md: "25px" } }}>
              <TraditionalMethod
                number="6"
                Icon={Icon6}
                Heading="Issue Resolution"
                Text="We are committed to resolving your issues promptly and effectively. Our support team is dedicated to providing solutions at your fingertips."
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TraditionalMethod
                number="7"
                Icon={Icon7}
                Heading="Manual Order Setup"
                Text="The notary must now create folders and spreadsheets to track the details of accepted invitations and new jobs. When do they have time?"
              />
            </Grid>
          </Grid>

          <Grid
            id="Howitworks"
            container
            spacing={2}
            sx={{ marginTop: { xs: '70px', md: "150px" }, paddingBottom: { xs: "70px", md: "150px" } }}
          >

            <Grid item xs={12} md={12} sx={{ marginBottom: { xs: '0px', md: "60px" } }}>
              <Typography
                sx={{
                  fontSize: { xs: "34px", md: "40px" },
                  color: "#000000",
                  fontWeight: "600",
                  textAlign: { xs: "left", md: "center" },
                  marginBottom: { xs: '10px', md: '0px' }
                }}
              >
                How <span style={{ color: "#F26F21" }}> GoSignings</span> Makes
                This Magic Happen
              </Typography>
              <Typography
                sx={{
                  fontSize: "21px",
                  color: "#000000",
                  fontWeight: "100",
                  textAlign: { xs: "left", md: "center" },
                  marginBottom: { md: "70px", xs: '0px' },
                  marginTop: { md: "10px" },
                }}
              >
                “Capturing New Business To Fill Your Calendar And Generate More
                Money”
              </Typography>
            </Grid>
            {/* Timeline Section */}
            <Grid item xs={12} md={4} sm={6}>
              <TraditionalMethod
                number="1"
                Icon={Icon8}
                Heading="Linking Your Signing Agency"
                Text={["From Notary Manual Process Of Linking The Signing Agency With Our AI Technology Ensures Every Invitation Is Being Acknowledged."]}
                isOrderedList={true}
              // Text="From Notary Manual Process Of Linking The Signing Agency With Our AI Technology Ensures Every Invitation Is Being Acknowledged."
              />
            </Grid>
            <Grid item xs={12} md={4} sm={6}>
              <TraditionalMethod
                number="2"
                Icon={Icon12}
                Heading="Auto-detected job invites"
                Text={["GoSignings automatically monitors your channels for new invitations from your signing agencies while you’re driving and working."]}
                isOrderedList={true}
              // Text="GoSignings automatically monitors your channels for new invitations from your signing agencies while you’re driving and working."
              />
            </Grid>
            {/* Widened Section */}
            <Grid item xs={12} md={4} sm={6}>
              <TraditionalMethod
                number="3"
                Icon={Icon2}
                Heading="Automated invitation review"
                Text={["GoSignings automatically extracts key information from new invitations for automated evaluation."]}
                isOrderedList={true}
              // Text="GoSignings automatically extracts key information from new invitations for automated evaluation."
              />
            </Grid>
            <Grid item xs={12} md={4} sm={6}>
              <TraditionalMethod
                number="4"
                Icon={Icon3}
                Heading="Automated evaluation"
                Text={["GoSignings evaluates the new offer and considers whether it is acceptable for you by checking your working hours, schedule, closing location, drive times, and fee offered."]}
                isOrderedList={true}
              // Text="GoSignings evaluates the new offer and considers whether it is acceptable for you by checking your working hours, schedule, closing location, drive times, and fee offered."
              />
            </Grid>
            <Grid item xs={12} md={4} sm={6}>
              <TraditionalMethod
                number="5"
                Icon={Icon3}
                Heading="Opportunity Evaluation:"
                Text={["Evaluates user-defined criteria:",
                  "Working hours.",
                  "Minimum fee requirement.",
                  "Schedule conflicts.",
                  "Drive time to and from appointments."]}
                isOrderedList={true}

              />
            </Grid>
            {/* Widened Section */}
            <Grid item xs={12} md={4} sm={6}>
              <TraditionalMethod
                number="6"
                Icon={Icon4}
                Heading="Automated RSVP:"
                Text={["GoSignings will accept or decline new offers directly to your signing agencies, on your behalf."]}
                isOrderedList={true}
              />
            </Grid>
            <Grid item xs={12} md={4} sm={6}>
              <TraditionalMethod
                number="7"
                Icon={Icon5}
                Heading="Confirmation Reception:"
                Text={["Detects and extracts information from confirmation messages.",
                  "Updates order status from pending to accepted.",
                  "Sends Notification to You Regarding Orders Updates."]}
                isOrderedList={true}
              />
            </Grid>
            <Grid item xs={12} md={4} sm={6}>
              <TraditionalMethod
                number="8"
                Icon={Icon6}
                Heading="Error Handling:"
                Text={["Manages cases where no confirmation is received, updating status accordingly.",
                  "Removes unconfirmed orders from the calendar."]}
                isOrderedList={true}
              />
            </Grid>
            <Grid item xs={12} md={4} sm={6}>
              <TraditionalMethod
                number="9"
                Icon={Icon7}
                Heading="Auto-creation of order records"
                Text={["Allowing you to access, view, and manage your newly accepted orders with easy-to-use on-screen forms."]}
                isOrderedList={true}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <CoreFeatures />
      {/* ////////////////////////////////// */}

      <ManageJob />

      {/* ////////////////////////////////////toomarow first periorty...../////////////////////////////////////////////// */}
      <Box
        sx={{
          backgroundImage: `url(${stopbg})`, // Set background image
          backgroundSize: 'cover', // Ensure the background covers the entire box
          backgroundPosition: 'center', // Center the background
          padding: { xs: 0, md: 4 },
          minHeight: 'auto', // Full screen height
        }}
      >
        <Container>
          <Grid container spacing={2}>
            {/* 1st Line: One column full width */}
            <Grid item xs={12}>
              <Box
                sx={{
                  backgroundColor: '', // You can style this box as needed
                  // padding: 4,
                }}
              >
                <Typography variant="h4" sx={{ fontSize: { xs: '36px', md: '40px' }, color: '#000000', fontWeight: 'bolder', textAlign: 'center', padding: { md: '20px 0px', xs: '60px 0px 0px 0px', sm: '20px 0px' } }}> <span style={{ color: '#F26F21' }}>GoSignings: </span>
                  Your One-Stop Solution for Success
                </Typography>
              </Box>
            </Grid>

            {/* 2nd Line: Two Columns */}
            <Grid container item xs={12} spacing={2}>
              {/* 1st Column (Image) */}
              <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>

                <Box
                  component="img"
                  src={onestep}
                  alt=""
                  sx={{ height: { xs: 'auto', md: '461px', sm: 'auto' }, width: { xs: '100%', md: 'auto', sm: '80%' }, padding: { md: 'auto', xs: '35px 0px' } }}
                />

              </Grid>

              {/* 2nd Column (Text) */}
              <Grid item xs={12} md={6}>
                <Box>
                  <Typography variant="h5" gutterBottom sx={{ padding: { xs: '20px 0px', md: '20px 0px' }, fontFamily: 'inter', fontSize: { xs: '24px', md: '18px', sm: '24px' }, letterSpacing: { md: '1.4px', xs: 'auto' }, color: '#000000', textAlign: 'left', lineHeight: { xs: '11  0%', md: '130%' }, fontWeight: { xs: 600, md: 700, sm: 600 } }}>
                    GoSignings simplifies your life by:
                  </Typography>
                  <Typography variant="body1" sx={{ padding: { xs: '20px 0px', md: '20px 0px' }, fontFamily: 'inter', fontSize: '18px', color: '#000000', textAlign: 'left', lineHeight: { xs: '110%', md: '130%' }, fontWeight: { xs: 200, md: 200 } }}>
                    <span style={{ fontWeight: 600 }}>Saving You Time:</span> Focus on your core business activities while GoSignings handles the mundane tasks.
                  </Typography>
                  <Typography variant="body1" sx={{ padding: { xs: '20px 0px', md: '20px 0px' }, fontFamily: 'inter', fontSize: '18px', color: '#000000', textAlign: 'left', lineHeight: { xs: '110%', md: '130%' }, fontWeight: { xs: 200, md: 200 } }}>
                    <span style={{ fontWeight: 600 }}>Boosting Your Efficiency:</span>  Get more done in less time with streamlined workflows.
                  </Typography>
                  <Typography variant="body1" sx={{ padding: { xs: '20px 0px 60px 0px', md: '20px 0px' }, fontFamily: 'inter', fontSize: '18px', color: '#000000', textAlign: 'left', lineHeight: { xs: '110%', md: '130%' }, fontWeight: { xs: 200, md: 200 } }}>
                    <span style={{ fontWeight: 600 }}>Maximizing Your Income:</span>  Never miss an opportunity and maximize your earnings potential.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>


      {/* <ImageText
        hei="auto"
        mainhead="Your One-Stop Solution for Success"
        hightlighted="GoSignings:"
        Image={onestep}
        Heading="GoSignings simplifies your life by:"
        Text="GoSignings, your go-to mobile notary and job closing AI management system. Automate time-consuming tasks and focus on delivering top-notch service and closing more jobs. Revolutionize your workflow with GoSignings!"
      /> */}
      {/* ////////////////////////////////////toomarow first periorty...../////////////////////////////////////////////// */}

      <Jobmanagment />

      {/*                 Footer                   */}
      <Box id="Pricing"
        sx={{
          backgroundColor: "#d6ebcc",
          display: { lg: "block", xs: "block" }, // Show on all screen sizes
          paddingTop: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "10px 0px",
          }}
        >
          <Logo />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: { xs: "flex-start", md: "center" },
            flexDirection: { xs: "column", md: "row" },
            paddingLeft: "16px",
          }}
        >
          <SelectLanguage color="#000000" />
          {/* <Typography sx={{ fontSize: "16px", mx: 2, color: 'black' }}>
                        @copyrights
                    </Typography> */}
          <Typography
            sx={{ fontSize: "16px", mx: 2, cursor: "pointer", color: "black" }}
            onClick={TermsCondition}
          >
            Terms and Conditions
          </Typography>
          {/* <Link
            to="/privacypolicy"
            style={{ textDecoration: "none", color: "white" }}
          >
            <Typography sx={{ fontSize: "16px", mx: 2 }}>
              Privacy Policy
            </Typography>
          </Link> */}
          <Link
            to="/helpcenter"
            style={{ textDecoration: "none", color: "black" }}
          >
            <Typography sx={{ fontSize: "16px", mx: 2 }}>Help</Typography>
          </Link>
          <Link
            to="/contactus"
            style={{ textDecoration: "none", color: "black" }}
          >
            <Typography sx={{ fontSize: "16px", mx: 2 }}>Contact Us</Typography>
          </Link>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            pb: 4,
            mt: 4,
          }}
        >
          <a
            href="https://www.instagram.com/gosignings/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={InstagramIcon} style={{ margin: "0 20px" }} />
          </a>
          <a
            href="https://www.facebook.com/profile.php?"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={FacebookIcon} style={{ margin: "0 20px" }} />
          </a>
          <a
            href="https://www.linkedin.com/in/jeffrey-freeman-50797b316/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={LinkedinIcon} style={{ margin: "0 20px" }} />
          </a>
          <a
            href="https://x.com/GoSignings"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Twitter} style={{ margin: "0 20px" }} />
          </a>
        </Box>
        <Typography
          sx={{ fontSize: "16px", textAlign: "center", color: "#000000" }}
        >
          Copyright 2024, CentralMassSoftware Development, Inc.
        </Typography>
      </Box>

      <TermsAndConditions
        open={showModal}
        handleClose={() => setShowModal(false)}
      />
    </Box>
  );
};

export default LandingPage_1;
